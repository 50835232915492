<template>
  <b-modal
    id="modal-edit-user"
    ref="modal-edit-user"
    centered
    title="Ajouter un client"
    @ok="create"
    size="lg"
  >
    <div v-if="isCreatingCompany">
      <div class="text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        Chargement des détails du client...
      </div>
    </div>
    <validation-observer v-else ref="formCreate">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="10">
                <b-form-group label-for="customerType">
                  <validation-provider
                    #default="{ errors }"
                    name="CustomerType"
                    rules="required"
                  >
                    <b-form-radio-group
                      v-model="customer.customerTypeRef"
                      :options="optionCustomerType"
                      class="demo-inline-spacing mb-1"
                      value-field="value"
                      text-field="label"
                      disabled-field="disabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="Color">
                    <v-swatches
                      show-fallback
                      fallback-input-type="color"
                      popover-x="left"
                      id="color"
                      :state="errors.length > 0 ? false : null"
                      v-model="customer.color"
                    ></v-swatches>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="customer.customerTypeRef == 1" cols="12">
            <b-form-group label="Nom de la société *" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="name"
                :rules="customer.customerTypeRef == 1 ? 'required' : ''"
              >
                <b-form-input
                  id="name"
                  v-model="customer.name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="4">
                <b-form-group label="Civilité" label-for="civility">
                  <validation-provider #default="{ errors }" name="Civility">
                    <v-select
                      id="civility"
                      :state="errors.length > 0 ? false : null"
                      :reduce="(type) => type.value"
                      v-model="customer.civility"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="optionCivility"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  :label="customer.customerTypeRef == 0 ? 'Nom *' : 'Nom'"
                  label-for="lastName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Lastname"
                    :rules="customer.customerTypeRef == 0 ? 'required' : ''"
                  >
                    <b-form-input
                      id="lastName"
                      v-model="customer.lastName"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Prénom" label-for="firstName">
                  <validation-provider #default="{ errors }" name="Firstname">
                    <b-form-input
                      id="firstName"
                      v-model="customer.firstName"
                      :state="errors.length > 0 ? false : null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-for="(itemAddress, indexAddress) in customer.addresses"
            :key="indexAddress"
            cols="12"
          >
            <b-form-group :label-for="'labelAddress_' + indexAddress">
              <template slot="label">
                <div class="d-flex w-full justify-content-between">
                  <label :for="'labelAddress_' + indexAddress">{{
                    customer.addresses.length > 1
                      ? "Adresse " + (indexAddress + 1)
                      : "Adresse"
                  }}</label>
                  <div class="flex" v-if="customer.addresses.length > 1">
                    <check-circle-icon
                      v-b-tooltip.hover.top="'Adresse par défaut'"
                      v-if="itemAddress.default"
                      :color="itemAddress.default ? 'green' : ''"
                      size="1.5x"
                      class="custom-class"
                    ></check-circle-icon>
                    <star-icon
                      v-else
                      @click="addFavoriteAddress(indexAddress)"
                      size="1.5x"
                      class="cursor-pointer custom-class"
                    ></star-icon>
                    <trash-2-icon
                      @click="deleteAddress(itemAddress, indexAddress)"
                      size="1.5x"
                      class="cursor-pointer custom-class"
                    ></trash-2-icon>
                  </div>
                </div>
              </template>
              <validation-provider #default="{ errors }" name="LabelAddress">
                <b-form-input
                  class="mb-1"
                  v-if="customer.addresses.length > 1"
                  :id="'labelAddress_' + indexAddress"
                  v-model="itemAddress.label"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé (optionnel)"
                />
              </validation-provider>
              <validation-provider #default="{ errors }" name="Address">
                <b-form-input
                  class="mb-1"
                  :id="'address_' + indexAddress"
                  v-model="itemAddress.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Rue et n° de rue"
                />
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="AddressComplement"
              >
                <b-form-input
                  class="mb-1"
                  :id="'addressComplement_' + indexAddress"
                  v-model="itemAddress.addressComplement"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Bâtiment, Appartement.."
                />
              </validation-provider>
              <b-row>
                <b-col cols="4">
                  <validation-provider #default="{ errors }" name="Zipcode">
                    <b-form-input
                      class="mb-1"
                      :id="'zipcode_' + indexAddress"
                      v-model="itemAddress.zipCode"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Code postal"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="8">
                  <validation-provider #default="{ errors }" name="City">
                    <b-form-input
                      class="mb-1"
                      :id="'city_' + indexAddress"
                      v-model="itemAddress.city"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ville"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <validation-provider #default="{ errors }" name="Country">
                <b-form-input
                  class="mb-1"
                  :id="'country_' + indexAddress"
                  v-model="itemAddress.country"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Pays"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="mb-1" cols="12">
            <small @click="addAddress" class="cursor-pointer text-primary"
              >+ Ajouter une adresse</small
            >
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="6">
                <b-row
                  v-for="(itemEmail, indexEmail) in customer.emails"
                  :key="indexEmail"
                >
                  <b-col cols="12">
                    <b-form-group :label-for="'labelEmail' + indexEmail">
                      <template slot="label">
                        <div class="d-flex w-full justify-content-between">
                          <label :for="'labelEmail' + indexEmail">{{
                            customer.emails.length > 1
                              ? "Email " + (indexEmail + 1)
                              : "Email"
                          }}</label>
                          <div v-if="customer.emails.length > 1">
                            <check-circle-icon
                              v-b-tooltip.hover.top="'Email par défaut'"
                              v-if="itemEmail.default"
                              :color="itemEmail.default ? 'green' : ''"
                              size="1.5x"
                              class="custom-class"
                            ></check-circle-icon>
                            <star-icon
                              v-else
                              @click="addFavoriteEmail(indexEmail)"
                              size="1.5x"
                              class="cursor-pointer custom-class"
                            ></star-icon>

                            <trash-2-icon
                              @click="deleteEmail(itemEmail, indexEmail)"
                              size="1.5x"
                              class="cursor-pointer custom-class"
                            ></trash-2-icon>
                          </div>
                        </div>
                      </template>
                      <validation-provider
                        v-if="customer.emails.length > 1"
                        #default="{ errors }"
                        name="labelEmail"
                      >
                        <b-form-input
                          id="labelEmail"
                          v-model="itemEmail.label"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Libellé (optionnel)"
                          class="mb-1"
                        />
                      </validation-provider>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="itemEmail.email"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <small @click="addEmail" class="cursor-pointer text-primary"
                  >+ Ajouter un email</small
                >
              </b-col>
              <b-col cols="6">
                <b-row
                  v-for="(itemPhone, indexPhone) in customer.phones"
                  :key="indexPhone"
                >
                  <b-col cols="12">
                    <b-form-group :label-for="'labelPhone' + indexPhone">
                      <template slot="label">
                        <div class="d-flex w-full justify-content-between">
                          <label :for="'labelPhone' + indexPhone">{{
                            customer.phones.length > 1
                              ? "Téléphone " + (indexPhone + 1)
                              : "Téléphone"
                          }}</label>
                          <div v-if="customer.phones.length > 1">
                            <check-circle-icon
                              v-b-tooltip.hover.top="'Téléphone par défaut'"
                              v-if="itemPhone.default"
                              :color="itemPhone.default ? 'green' : ''"
                              size="1.5x"
                              class="custom-class"
                            ></check-circle-icon>
                            <star-icon
                              v-else
                              @click="addFavoritePhone(indexPhone)"
                              size="1.5x"
                              class="cursor-pointer custom-class"
                            ></star-icon>
                            <trash-2-icon
                              @click="deletePhone(itemPhone, indexPhone)"
                              size="1.5x"
                              class="cursor-pointer custom-class"
                            ></trash-2-icon>
                          </div>
                        </div>
                      </template>
                      <validation-provider
                        v-if="customer.phones.length > 1"
                        #default="{ errors }"
                        name="labelPhone"
                      >
                        <b-form-input
                          id="labelPhone"
                          v-model="itemPhone.label"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Libellé (optionnel)"
                          class="mb-1"
                        />
                      </validation-provider>
                      <validation-provider
                        #default="{ errors }"
                        name="Phone"
                        rules="phone"
                      >
                        <b-form-input
                          id="phone"
                          v-model="itemPhone.phone"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <small @click="addPhone" class="cursor-pointer text-primary"
                  >+ Ajouter un téléphone</small
                >
              </b-col>
            </b-row>
          </b-col>
          <b-col class="mb-1 mt-1" cols="12">
            <b-button
              @click="displayMore = !displayMore"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              >{{ displayMore ? "Moins.." : "Plus.." }}</b-button
            >
          </b-col>
          <b-col v-if="displayMore" cols="12">
            <b-form-group
              v-if="customer.customerTypeRef == 1"
              label="Siret"
              label-for="siret"
            >
              <validation-provider #default="{ errors }" name="Siret">
                <b-form-input
                  id="siret"
                  v-model="customer.siret"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="customer.customerTypeRef == 1"
              label="Numéro de TVA"
              label-for="tvaNumber"
            >
              <validation-provider #default="{ errors }" name="TvaNumber">
                <b-form-input
                  id="tvaNumber"
                  v-model="customer.tvaNumber"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </b-form-group>
            <b-form-group label="Compte client" label-for="customerAccount">
              <validation-provider #default="{ errors }" name="CustomerAccount">
                <b-form-input
                  id="customerAccount"
                  v-model="customer.customerAccount"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tags" label-for="tags">
              <validation-provider #default="{ errors }" name="Tags">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  taggable
                  push-tags
                  id="tags"
                  :state="errors.length > 0 ? false : null"
                >
                  <template v-slot:no-options>
                    <template> Aucun résultat trouvé </template>
                  </template>
                  <li class="border-top p-1" slot="list-footer">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="w-100"
                      >+ Nouveau tag</b-button
                    >
                  </li>
                </v-select>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Notes" label-for="notes">
              <validation-provider #default="{ errors }" name="Notes">
                <b-form-textarea
                  id="notes"
                  v-model="customer.notes"
                  :state="errors.length > 0 ? false : null"
                  rows="3"
                />
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Contacts associés"
              label-for="contactsAssociated"
            >
              <validation-provider
                #default="{ errors }"
                name="ContactsAssociated"
              >
                <!-- <v-select
                  id="contactsAssociated"
                  :loading="isLoadingContact"
                  :state="errors.length > 0 ? false : null"
                  v-model="customer.contactsAssociated"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="
                    contactsList.map((elem) => {
                      return { label: elem.label, value: elem.id };
                    })
                  "
                  :reduce="(elem) => elem.value"
                >
                <template v-slot:no-options>
                    <template>
                      Aucun résultat trouvé
                    </template>
                    <li class="border-top p-1" slot="list-footer">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="w-100"
                      >+ Nouveau contact</b-button
                    >
                  </li>
                  </template>
                </v-select> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right"
          @click="ok()"
        >
          Ajouter
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="float-right mr-1"
          @click="cancel()"
        >
          Annuler
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import VSwatches from "vue-swatches";
import { VBTooltip } from "bootstrap-vue";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
import { ECustomerType, ECivility } from "@/types/api-orisis/enums/enums";
import { Trash2Icon, StarIcon, CheckCircleIcon } from "vue-feather-icons";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");
let optionCustomerType = [
  {
    label: ECustomerType[ECustomerType.Particulier],
    value: ECustomerType.Particulier,
  },
  {
    label: ECustomerType[ECustomerType.Professionnel],
    value: ECustomerType.Professionnel,
  },
];
let optionCivility = [
  {
    label: ECivility[ECivility.Monsieur],
    value: ECivility.Monsieur,
  },
  {
    label: ECivility[ECivility.Madame],
    value: ECivility.Madame,
  },
  {
    label: ECivility[ECivility.Autre],
    value: ECivility.Autre,
  },
];
export default {
  data() {
    return {
      displayMore: false,
      optionCustomerType: optionCustomerType,
      optionCivility: optionCivility,
      required,
      url,
      email,
      customer: {
        customerTypeRef: ECustomerType.Particulier,
        name: "",
        civility: "",
        lastName: "",
        firstName: "",
        addresses: [
          {
            address: "",
            addressComplement: "",
            city: "",
            zipCode: "",
            country: "France",
            default: true,
            label: "",
          },
        ],
        emails: [
          {
            label: "",
            email: "",
            default: true,
          },
        ],
        phones: [
          {
            label: "",
            phone: "",
            default: true,
          },
        ],
        siret: "",
        tvaNumber: "",
        notes: "",
        customerAccount: "",
        contactAssociated: [],
        status: true,
        color: "#0c3571",
        customerTags: [],
        workspaceId: this.$store.getters.workspaceSelected,
      },
    };
  },
  methods: {
    initializeForm() {
      this.customer = {
        customerTypeRef: ECustomerType.Particulier,
        name: "",
        civility: "",
        lastName: "",
        firstName: "",
        addresses: [
          {
            address: "",
            addressComplement: "",
            city: "",
            zipCode: "",
            country: "France",
            default: true,
            label: "",
          },
        ],
        emails: [
          {
            label: "",
            email: "",
            default: true,
          },
        ],
        phones: [
          {
            label: "",
            phone: "",
            default: true,
          },
        ],
        siret: "",
        tvaNumber: "",
        notes: "",
        customerAccount: "",
        contactAssociated: [],
        status: true,
        color: "#0c3571",
        customerTags: [],
        workspaceId: this.$store.getters.workspaceSelected,
      };
    },
    addEmail() {
      this.customer.emails.push({
        label: "",
        email: "",
        default: false,
      });
    },
    addPhone() {
      this.customer.phones.push({
        label: "",
        phone: "",
        default: false,
      });
    },
    addAddress() {
      this.customer.addresses.push({
        address: "",
        addressComplement: "",
        city: "",
        zipCode: "",
        country: "France",
        default: false,
        label: "",
      });
    },
    addFavoriteAddress(index) {
      for (let i = 0; i < this.customer.addresses.length; i++) {
        const address = this.customer.addresses[i];
        if (i == index) {
          address.default = true;
        } else {
          address.default = false;
        }
      }
    },
    addFavoriteEmail(index) {
      for (let i = 0; i < this.customer.emails.length; i++) {
        const email = this.customer.emails[i];
        if (i == index) {
          email.default = true;
        } else {
          email.default = false;
        }
      }
    },
    addFavoritePhone(index) {
      for (let i = 0; i < this.customer.phones.length; i++) {
        const phone = this.customer.phones[i];
        if (i == index) {
          phone.default = true;
        } else {
          phone.default = false;
        }
      }
    },
    deleteEmail(item, index) {
      this.customer.emails.splice(index, 1);
      if (item.default && index == this.customer.emails.length) {
        this.customer.emails[index - 1].default = true;
      } else if (item.default) {
        this.customer.emails[index].default = true;
      }
    },
    deletePhone(item, index) {
      this.customer.phones.splice(index, 1);
      if (item.default && index == this.customer.phones.length) {
        this.customer.phones[index - 1].default = true;
      } else if (item.default) {
        this.customer.phones[index].default = true;
      }
    },
    deleteAddress(item, index) {
      this.customer.addresses.splice(index, 1);
      if (item.default && index == this.customer.addresses.length) {
        this.customer.addresses[index - 1].default = true;
      } else if (item.default) {
        this.customer.addresses[index].default = true;
      }
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreate.validate().then((success) => {
        if (success) {
          this.createCompany({ customer: this.customer, that: this });
          this.$nextTick(() => {
            this.$refs["modal-create-customer"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createCompany"]),
  },
  computed: {
    ...mapGetters([
      "isCreatingCompany",
      "contactsList",
      "isLoadingContact",
    ]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VSwatches,
    StarIcon,
    Trash2Icon,
    CheckCircleIcon,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
};
</script>

<style>
</style>